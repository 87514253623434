<script>
export default {
	props: {
		order: Object,
		creditCardForm: Object,
		data: Object,
	},

	mounted() {
		document.getElementById('first-data-form')?.remove()

		const form = document.createElement('form')
		form.setAttribute('id', 'first-data-form')
		form.setAttribute('action', this.data.action)
		form.setAttribute('method', 'post')
		form.style.display = 'none'

		const inputs = {
			...this.data.inputs,
			cardnumber: this.creditCardForm.number,
			expmonth: this.creditCardForm.expirationMonth,
			expyear: this.creditCardForm.expirationYear,
			cvm: this.creditCardForm.cvv,
		}

		for (var name in inputs) {
			var input = document.createElement('input')
			input.setAttribute('name', name)
			input.setAttribute('value', inputs[name])
			input.setAttribute('type', 'hidden')
			form.appendChild(input)
		}

		document.body.appendChild(form)
		form.submit()
	},
}
</script>

<template>
	<div></div>
</template>
